import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
  JointholderRelationMaster,
} from '../redux-store/types/api-types';
import {
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  APPLICATION_TYPE,
  BloodRelations,
  dateDematAccountDetails,
  DistributorTypesForUserManageMent,
  dlclIdFieldValidationDate,
  DLCLMasters,
  PlansCategoryMasters,
  SALT,
  SALT2,
  USER_ROLES,
} from './constant';
import { ToWords } from 'to-words';
import { numberRegex } from './regex';
import _ from 'lodash';
import { FundProps } from '../redux-store/types/funds';
import en from '../lang/en-us';

export const maskMobileNumber = (mobileNumber: string): string => {
  if (!mobileNumber) {
    return '';
  }
  return (
    mobileNumber.slice(0, 2) +
    mobileNumber.slice(2, mobileNumber.length - 2).replace(/\d/g, '*') +
    mobileNumber.slice(mobileNumber.length - 2)
  );
};

export const getApplicantName = (index: number, isNomineeOrBank?: boolean): string => {
  if (index === 1) {
    return isNomineeOrBank ? 'First' : 'First/Sole';
  } else if (index === 2) {
    return 'Second';
  } else {
    return 'Third';
  }
};

export const minDateForContributor = (): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const getFirstHolderName = (applicants: Partial<Applicant>[]): string => {
  return (
    applicants.find((applicant) => ['firstapplicant', '1'].includes(applicant.applicant_type || ''))
      ?.name || 'N/A'
  );
};

export const getApplicantType = (applicantType: string | undefined): string => {
  if (applicantType) {
    if (['firstapplicant', '1'].includes(applicantType)) {
      return '1';
    } else if (['secondapplicant', '2'].includes(applicantType)) {
      return '2';
    } else {
      return '3';
    }
  }
  return '';
};

export const formatToIndianCurrency = (value: number | null): string => {
  const options = { style: 'currency', currency: 'INR' };
  if (value) {
    return new Intl.NumberFormat('en-IN', options).format(value);
  }
  return '';
};

export function toExactSubstr(str: string): string {
  const a = str.split('.');
  if (a.length == 2) {
    return a[0].concat('.', a[1].substr(0, 2));
  }
  return str;
}

export const formatCurrency = (value?: number | null): string => {
  const formats = ['K', 'L', 'Cr'];
  let amount: any = Number(value);
  formats.some((key, i) => {
    const size = Math.pow(10, i === 0 ? 3 : 3 - 1);
    if (size <= amount) {
      amount = toExactSubstr(`${amount / size}`);
      if (amount >= 100 && i < formats.length - 1) {
        i++;
      } else {
        // amount = (amount * decPlaces) / decPlaces;
        amount += ' ' + formats[i];
        return true;
      }
    } else {
      return true;
    }
  });
  return amount;
};

export const EIGHTEEN_YEARS_IN_MILLISECONDS = 18 * 31556926 * 1000; //  18 * no of seconds in a Year * 1000

export const isMinor = (dateOfBirth = ''): boolean => {
  return new Date().getTime() - new Date(dateOfBirth).getTime() < EIGHTEEN_YEARS_IN_MILLISECONDS;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countryCodes = require('country-codes-list');
export const getCountryCodes = (): { label: string; key: string; countryCode: string }[] => {
  const countryCodesObject: { [key: string]: string } = countryCodes.customList(
    'countryCode',
    '{countryNameEn}: +{countryCallingCode}'
  );

  const modifiedCodes = Object.keys(countryCodesObject).map((cc) => ({
    label: countryCodesObject[cc],
    key: countryCodesObject[cc].split(':')[1].trim(),
    countryCode: cc,
  }));
  return modifiedCodes;
};

export const getAddressFields = (addressType: string): ApplicantAddressType => {
  return {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    country: addressType === 'overseas' ? '' : 'INDIA',
    pincode: '',
    permanentAddressSameAsCorresponding: false,
    address_type: addressType,
    isActive: true,
    fetchedFromKRA: null,
  };
};

export const getAddressData = (
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  return (
    addresses?.find((address) => address.address_type === addressType) ||
    getAddressFields(addressType)
  );
};

export function getRelation(relationValue?: string | null): boolean {
  return ['Mother', 'Father', 'Daughter', 'Son', 'Spouse', 'Brother', 'Sister', ''].includes(
    relationValue || ''
  );
}

export const saveForLater = (
  role: string,
  id?: string,
  applicant1ReferenceId?: string | null
): { pathname: string; state?: { id: string } } => {
  return role === USER_ROLES.INVESTOR
    ? {
        pathname: `/investment-details/${applicant1ReferenceId}/application-details`,
      }
    : role === USER_ROLES.POAAPPROVER || role === USER_ROLES.AMCAPPROVER
    ? {
        pathname: `/application-details`,
        state: id ? { id } : undefined,
      }
    : { pathname: '/applications' };
};

export const maxAge = (dateOfBirth = ''): boolean => {
  const thisYear = new Date().getFullYear();
  return thisYear - new Date(dateOfBirth).getFullYear() > 125;
};

export function currencyConversion(num?: string | number | null): string | null {
  if (!num) return '';
  const toWords = new ToWords();
  const words = toWords.convert(Number(num), { currency: true });
  return words;
}

export function checkIfApplicationIsNonIndividual({ applicationType }: ApplicationProps): boolean {
  return applicationType === APPLICATION_TYPE.NON_INDIVIDUAL;
}

// eslint-disable-next-line
export const preventSpecialCharacters = (e: any) => {
  if (
    !((e.ctrlKey || e.metaKey) && e.keyCode == 67) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 86) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 88) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 90) &&
    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].includes(e.key) &&
    !numberRegex.test(e.key)
  ) {
    e.preventDefault();
  }
};
export function allowOnlyNumbers(e: any): void {
  if (['-', '+', 'e', 'E'].includes(e.key)) {
    e.preventDefault();
  }
}
export const permanentAddressSameAsCorresponding = (
  updatedCorrespondingAddress: Partial<ApplicantAddressType>,
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  // eslint-disable-next-line
  const { address1, address2, city, pincode, state, country, ...rest } =
    updatedCorrespondingAddress;
  return {
    ...getAddressData(addressType, addresses),
    address1: address1,
    address2: address2,
    city: city,
    pincode: pincode,
    state: state,
    country: country,
    address_type: addressType,
  };
};
export const _updatedAddresses = (
  updatedAddresses: {
    nationality?: string | null | undefined;
    status?: string | null | undefined;
    permanent?: Partial<ApplicantAddressType> | undefined;
    correspondence?: Partial<ApplicantAddressType> | undefined;
    overseas?: Partial<ApplicantAddressType> | undefined;
    [key: string]: string | Partial<ApplicantAddressType> | null | undefined;
  },
  applicant: Partial<Applicant>
): Partial<ApplicantAddressType>[] => {
  const { nationality, status, ...addressProps } = updatedAddresses;
  return Object.keys(addressProps)
    .map((addressType) => {
      // if (applicant.nationality?.toLowerCase() === 'indian' && addressType === 'overseas') {
      //   return {};
      // }
      if (
        addressType === 'permanent' &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['overseas'],
          addressType,
          applicant.addresses
        );
      }
      if (
        addressType === 'permanent' &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding &&
        !addressProps['overseas']
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['correspondence'],
          addressType,
          applicant.addresses
        );
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['overseas'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['correspondence'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      return {
        ...getAddressData(addressType, applicant.addresses),
        ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
        address_type: addressType,
      };
    })
    .flat()
    .filter((address) => Object.keys(address).length);
};
export const applyRoleBasedStatus = (role: any): boolean => {
  return [
    USER_ROLES.RM,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.AMCAPPROVER,
    USER_ROLES.AMC_ADMIN,
  ].includes(role);
};

export const checkKraBasedOnStatus = (status: any): boolean => {
  return ![
    APPLICATION_LISTING_STATUS.invitationexpired,
    APPLICATION_LISTING_STATUS.signed,
    APPLICATION_LISTING_STATUS.completed,
    APPLICATION_LISTING_STATUS.rejected,
  ].includes(APPLICATION_LISTING_STATUS[status]);
};

export const applicationComparison = (
  existingApplication: Partial<ApplicationProps> | null,
  updatedApplication: Partial<ApplicationProps>
): boolean => {
  return _.isEqual(existingApplication, updatedApplication);
};

export const sendApplication_Nri = (application?: ApplicationProps | null): boolean | undefined => {
  return application?.applicants.map((applicant) => applicant.status).includes('NRI');
};

export const checkForCorrespondenceAddress = (
  nationality: string | null | undefined,
  status: string | null | undefined
): boolean => {
  return (
    nationality?.toLowerCase() === 'indian' &&
    applicantStatusMasters[status as string] === applicantStatusMasters.Individual
  );
};

export const setUpFeeCalculations = (
  comitmentAmount: number,
  setupFeePercentage: number
): { feeAmount: number; feeGst: number; totalSetupFee: number } => {
  const setupFeeAmount = (Number(comitmentAmount) * Number(setupFeePercentage)) / 100;
  const GstForSetupFee = (setupFeeAmount * 18) / 100;
  return {
    feeAmount: Number(setupFeeAmount),
    feeGst: Number(GstForSetupFee),
    totalSetupFee: Number(setupFeeAmount + GstForSetupFee),
  };
};

export const checkAddressField = (data?: string) => {
  if (data) {
    if (data.includes(' ,')) {
      return data.split(' ,').join(',') + ' ';
    }
    return data.includes(',') ? data + ' ' : data + ', ';
  }
  return '';
};

export const getBankAddress = (
  address1: string | undefined,
  address2: string | undefined,
  address3: string | undefined
): string => {
  return `${address2 ? checkAddressField(address1) || '' : address1 || '' || ''}${
    address3 ? checkAddressField(address2) || '' : address2 || ''
  }${address3 || ''}`;
};

export const numToAlpMonth = (numMonth: any, month: any) => {
  numMonth?.map((monthNum: any) => {
    if (monthNum === 1) {
      month.push('Jan');
    } else if (monthNum === 2) {
      month.push('Feb');
    } else if (monthNum === 3) {
      month.push('Mar');
    } else if (monthNum === 4) {
      month.push('Apr');
    } else if (monthNum === 5) {
      month.push('May');
    } else if (monthNum === 6) {
      month.push('Jun');
    } else if (monthNum === 7) {
      month.push('Jul');
    } else if (monthNum === 8) {
      month.push('Aug');
    } else if (monthNum === 9) {
      month.push('Sep');
    } else if (monthNum === 10) {
      month.push('Oct');
    } else if (monthNum === 11) {
      month.push('Nov');
    } else if (monthNum === 12) {
      month.push('Dec');
    }
  });
};

export const removeSingleQuote = (value: string | null | undefined): any => {
  if (value && value.includes("'")) return value.split("'").join('');
  else return value;
};

export function removeEqualOperator(value = ''): string {
  const lastTwoIndex = value.length - 2;
  let encodeString = value.slice(0, lastTwoIndex);
  const slicedStr = value.slice(lastTwoIndex, value.length);
  slicedStr.split('').forEach((char) => {
    if (char !== '=') {
      encodeString += char;
    }
  });
  return encodeString;
}

export function encodeBase64(value: string): string {
  const encodeValue = btoa(value);
  const encodeSubString = removeEqualOperator(encodeValue);
  const encodePayload = btoa(`${SALT}${encodeSubString}${SALT2}`);
  return encodePayload;
}

export function getNomineeRelation(relationValue?: string | null): boolean {
  return ['MOTHER', 'FATHER', 'DAUGHTER', 'SON', 'SPOUSE', 'BROTHER', 'SISTER', ''].includes(
    relationValue || ''
  );
}

export function applicationDownloadStatusCheck(status: string): boolean {
  return [
    'sent_to_applicant1',
    'approved_by_applicant1',
    'approved_by_applicant2',
    'approved_by_applicant3',
    'approved_by_authorised_signatories',
    'approved_by_fundmanager',
    'signed',
  ].includes(status);
}

export function updatedCommitmentAmount(
  minCommitmentAmount: number,
  applicants: Partial<Applicant>[],
  data: JointholderRelationMaster
): number | void {
  if (applicants.length === 2) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele)
      ?.toString();
    const relation = BloodRelations.includes(applicantRelation) ? applicantRelation : 'Others';
    const amount = data.singleJointHolder
      .map((jointHolder) => {
        if (jointHolder.jointHolder1RelationShip === relation) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
  if (applicants.length === 3) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele);
    const relation = applicantRelation.map((_relation) =>
      BloodRelations.includes(_relation as string) ? _relation : 'Others'
    );
    const amount = data.twoJointHolders
      .map((jointHolder) => {
        if (
          relation[applicants.length - 3] === jointHolder.jointHolder1RelationShip &&
          relation[applicants.length - 2] === jointHolder.jointHolder2RelationShip
        ) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
}

export const opsRmCheck = (loggedInUserId: string): boolean => {
  return ['607'].includes(loggedInUserId?.toString());
};

export const getdpIdField = (value: string): boolean => {
  return (
    DLCLMasters[value || ''] === DLCLMasters.cdsl || DLCLMasters[value || ''] === DLCLMasters.nsdl
  );
};

export const clearclIdField = (value: string): boolean => {
  return (
    DLCLMasters[(value as string) || ''] === DLCLMasters.none ||
    DLCLMasters[(value as string) || ''] === DLCLMasters.cdsl
  );
};

export const cleardpIdField = (value: string): boolean => {
  return DLCLMasters[(value as string) || ''] === DLCLMasters.none;
};

export const getclIdField = (value: string): boolean => {
  return DLCLMasters[value || ''] === DLCLMasters.nsdl;
};

export const isCDSL = (value: string): boolean => {
  return DLCLMasters[(value as string) || ''] === DLCLMasters.cdsl;
};

export const checkValidationBasedOnDate = (
  date: string | number | Date,
  validationStartDate: string
): boolean => {
  if (!date) return false;
  const dateForCompare = date ? new Date(date) : new Date();
  const dateForApplyValidations = new Date(validationStartDate);
  return dateForCompare.getTime() >= dateForApplyValidations.getTime();
};

export const getDPCLIDFieldVal = (fieldVal: { [key: string]: string }, createdAt: string) => {
  return Object.keys(fieldVal).filter((ele) => {
    if (checkValidationBasedOnDate(createdAt, dateDematAccountDetails)) {
      return ele !== 'none';
    }
    return ele;
  });
};

export const isIndividualDistributorType = (value: string): boolean =>
  value === DistributorTypesForUserManageMent.Individual;

export const getClassPlanOptionsOrDetails = (
  selectedFundId: string | number | null,
  fundsList: FundProps[]
) => {
  const selectedFund = fundsList?.find((f) => Number(f.id) === Number(selectedFundId)) as FundProps;
  return selectedFund?.plans?.map((plan) => ({ key: plan.id, value: plan.planDescription })) || [];
};
export const futureAge = (dateOfBirth = ''): boolean => {
  return new Date(dateOfBirth) > new Date();
};

export const dlclIdFieldMandatory = (): boolean => {
  return checkValidationBasedOnDate(new Date(), dlclIdFieldValidationDate);
};

/**
 * Replace with your file URL or API endpoint
 * @param fileUrl
 *
 * Desired file name
 * @param fileName
 */
export const handleDownload = async (fileUrl: string, fileName: string): Promise<void> => {
  // Fetch the file data

  const response = await fetch(fileUrl);
  if (!response.ok) {
    throw new Error(en.networkText.unableToProcess);
  }
  const blob = (await response.blob()) as Blob;

  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // Clean up
  link.remove();
  URL.revokeObjectURL(blobUrl);
};

export const sipTypeInvestmentCategory = (category: string): boolean => {
  return category === PlansCategoryMasters.SIP;
};
